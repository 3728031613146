import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';

const CustomTabs = ({ titles, content, onSelectTab }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleSelect = (index) => {
        setSelectedTabIndex(index);
        onSelectTab(index, titles[index]);
    };
    return (
        <ReactTabs selectedIndex={selectedTabIndex} onSelect={handleSelect}>
            <TabList>
                {titles.map((title, index) => (
                    <Tab key={index}>{title}</Tab>
                ))}
            </TabList>

            {content.map((panelContent, index) => (
                <TabPanel key={index}>{panelContent}</TabPanel>
            ))}
        </ReactTabs>
    );
};

export default CustomTabs;
