import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = ({ spin }) => {
	if (spin)
		return (
			<div className="flex items-center justify-center" style={{ width: "100%", height: "10vh" }}>
				<Spinner></Spinner>
			</div>
		);

	return (
		<div className="min-h-screen bg-white flex justify-center pt-[15%]">
			<div className="border-2 border-[#F16A1B] bg-[#FCE1D1] px-[16px] py-[12px] h-fit rounded-md animate-pulse">
				<p className="text-[#F16A1B] font-semibold">Loading...</p>
			</div>
		</div>
	);
};

const loader = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.span`
	width: 30px;
	height: 30px;
	display: inline-block;
	padding: 0px;
	border-radius: 100%;
	border: 2px solid;
	border-top-color: rgba(1, 50, 173, 0.65);
	border-bottom-color: rgba(1, 50, 173, 0.15);
	border-left-color: rgba(1, 50, 173, 0.65);
	border-right-color: rgba(1, 50, 173, 0.15);
	-webkit-animation: ${loader} 0.8s linear infinite;
	animation: ${loader} 0.8s linear infinite;
`;

export default Loader;
