import React, { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useCart } from "src/context/CartContext";
import { setToStorage } from "src/utils/functions";

const QuantityInput = ({ product }) => {
  const { cartState, dispatch } = useCart();

  const itemInCart = cartState?.items?.find(
    (item) => item?.product?.id === product?.id
  );

  useEffect(() => {
    setToStorage("cart", cartState);
  }, [cartState]);

  const addToCart = () => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        product: { ...product },
      },
    });
  };

  const removeFromCart = () => {
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: {
        product: { ...product },
      },
    });
  };

  const isDisabled = !itemInCart || itemInCart?.quantity === 0;
  return (
    <>
      <div className="bg-[#f3f3f3] rounded-[30px] flex items-center justify-between gap-[1rem] py-[0.8rem] px-[2rem] w-full">
        <button
          disabled={isDisabled}
          className={`${
            isDisabled ? "" : "bg-opacity-60"
          } text-primary cursor-pointer`}
          onClick={removeFromCart}
        >
          <AiOutlineMinus size="1.4rem" />
        </button>
        <p className="text-[1.4rem] text-primary">
          {itemInCart ? itemInCart?.quantity : 0}
        </p>
        <button
          className="text-primary cursor-pointer"
          onClick={addToCart}
          disabled={itemInCart?.quantity === parseInt(product?.qty)}
        >
          <AiOutlinePlus size="1.4rem" />
        </button>
      </div>
    </>
  );
};

export default QuantityInput;
