import axios from "axios";
import {
  formatValidationErrors,
  getFromStorage,
  renderToastMsg,
  // setToStorage,
} from "src/utils/functions";

const authApi = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_LOCAL_BASEURL}`
      : `${process.env.REACT_APP_PROD_BASEURL}`,
  headers: {
    "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Credentials": true,
  },
});

authApi.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response.status === 401) {
      renderToastMsg(error.response.data.message).then(() => {
        localStorage.clear();
      });
    } else if (error.response.status === 503) {
      renderToastMsg(error.response.data.message, "success");
    } else {
      renderToastMsg(
        formatValidationErrors(error.response.data.message),
        "error"
      );
      throw new Error(error.response.data.message, {
        cause: error.response.status,
      });
    }
  }
);

//* get schools
export const getAllSchools = () => {
  return authApi.get("api.php", { params: { action: "get_all_schools" } });
};

//* Sign in
export const signIn = async ({ schoolCode, username, password, staff }) => {
  try {
    const schoolDataResponse = await authApi.get("api.php", {
      params: { action: "get_school_details", schoolCode },
    });
    const schoolData = schoolDataResponse?.data;

    const loginData = {
      action: staff ? "signin_staff" : "signin_student",
      schoolCode,
      username: username,
      password: password,
    };

    const loginResponse = await authApi.post("api.php", loginData);
    const loginResult = loginResponse?.data;

    if (loginResult && schoolData) {
      const res = {
        data: {
          school: schoolData,
          user: loginResult?.user,
          token: loginResult?.token,
        },
        status: loginResponse?.status,
        message: loginResponse?.message,
      };

      return res;
    }
  } catch (error) {
    throw error;
  }
};

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_LOCAL_BASEURL}`
      : `${process.env.REACT_APP_PROD_BASEURL}`,
});

api.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "multipart/form-data";
  config.headers["Authorization"] = getFromStorage("token");
  return config;
}, null);

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response.status === 401) {
      renderToastMsg(error.response.data.message).then(() => {
        localStorage.clear();
        window.location.reload();
      });
    } else if (error.response.status === 503) {
      renderToastMsg(error.response.data.message, "success");
    } else {
      renderToastMsg(error.response.data.message);
      throw new Error(error.response.data.message, {
        cause: error.response.status,
      });
    }
  }
);

//? get inventory
export const getInventory = ({ category, searchParams }) => {
  return api.get("api.php", {
    params: { action: "get_inventory", category, searchParams },
  });
};
//? get categories
export const getCategories = () => {
  return api.get("api.php", { params: { action: "get_all_categories" } });
};

//? get all Trasactions
export const getAllTransaction = ({ type }) => {
  return api.get("api.php", {
    params: { action: "get_all_transaction", type },
  });
};

//? get all Orders
export const getAllOrders = ({ status }) => {
  return api.get("api.php", { params: { action: "get_all_orders", status } });
};

//? get pin status
export const getPinStatus = () => {
  return api.get("api.php", { params: { action: "allowance_pin_status" } });
};

//? update Transaction Pin
export const updateTransactionPin = (data) => {
  return api.post("api.php", data);
};

//? create Transaction Pin
export const createTransactionPin = (data) => {
  return api.post("api.php", data);
};

//? Debit Wallet
export const debitWallet = (data) => {
  return api.post("api.php", data);
};

//? get wallet balance
export const getWalletBal = () => {
  return api.get("api.php", { params: { action: "get_wallet_balance" } });
};

export const getOrder = (data) => {
  return api.post("api.php", data);
};
export const ValidatePin = (data) => {
  return api.post("api.php", data);
};

//? get payment Keys
export const getPaymentKeys = () => {
  return api.get("api.php", { params: { action: "get_payment_keys" } });
};
