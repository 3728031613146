import React from "react";
import emptyCart from "src/assets/images/empty.png";
import { renderNaira } from "src/utils/functions";
import { QuantityInput } from ".";
import { useCart } from "src/context/CartContext";

const Cart = ({ mobile, setIsCartModalOpen }) => {
    const { cartState, dispatch } = useCart();

    const removeItemFromCart = (product) => {
        dispatch({ type: "REMOVE_ITEM", payload: { product } });
    };

    const clearCart = () => {
        dispatch({ type: "CLEAR_CART" });

    };

    return (
        <>
            <div className={`rounded-[10px] ${!mobile ? "shadow p-[2rem] " : ""}`}>
                <div className={`flex  ${!mobile ? "justify-between" : "justify-end"} items-center`}>
                    {!mobile && (<h4 className='text-primary text-center text-[1.8rem] font-semibold'>
                        Your Order
                    </h4>)}
                    {cartState?.items.length > 0 && (
                        <button
                            className='text-red-400 font-medium text-[1.4rem]'
                            onClick={() => {
                                clearCart()
                                if (mobile) { setIsCartModalOpen(false) }
                            }}
                        >
                            Clear
                        </button>
                    )}
                </div>
                <div className='my-[3rem]'>
                    {cartState?.items.length > 0 ? (
                        <>
                            {cartState?.items?.map((cartItem, index) => (
                                <div key={index} className='my-[1.5rem]'>
                                    <div className='flex justify-between items-center text-[1.5rem]'>
                                        <p className='text-[#576378] font-normal'>
                                            <span className='font-semibold text-primary'>
                                                {cartItem?.quantity}x
                                            </span>{" "}
                                            {cartItem.product?.name}
                                        </p>
                                        <p className='text-[#576378] font-normal'>
                                            {" "}
                                            {renderNaira(
                                                cartItem?.product?.salesPrice * cartItem?.quantity
                                            )}
                                        </p>
                                    </div>

                                    <div className='flex justify-between items-center my-[1rem]'>
                                        <div>
                                            <QuantityInput product={cartItem?.product} />
                                        </div>
                                        <button
                                            className='text-red-400 font-medium text-[1.4rem]'
                                            onClick={() => removeItemFromCart(cartItem.product)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className=' my-[3rem] border-t border-t-[#ebebeb]'>
                                <div className='flex justify-between items-center text-[1.5rem] font-medium my-[2rem]'>
                                    <p className='text-[#576378] '>Total</p>
                                    <p className='text-primary '>

                                        {renderNaira(cartState?.total)}
                                    </p>
                                </div>
                            </div>

                            <button
                                onClick={() => {
                                    window.open("/checkout", "_self");
                                }}
                                className='bg-primary text-white px-[1rem] py-[1rem] text-[1.4rem] rounded-[5rem] hover:scale-[.98] transition-border transform duration-300 ease-in-out hover:transform w-full'
                            >
                                Proceed to Checkout
                            </button>
                        </>
                    ) : (
                        <div>
                            <img
                                src={emptyCart}
                                alt='empty cart'
                                className='w-full mx-auto'
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Cart;
