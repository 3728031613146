import React, { createContext, useReducer, useContext, useEffect } from "react";
import {
  getFromStorage,
  setToStorage,
  renderToastMsg,
} from "src/utils/functions";

// Create a context
const CartContext = createContext();

// Initial state for the cart
const initialState = getFromStorage("cart") || {
  items: [],
  total: 0.0,
};

// Reducer function
const cartReducer = (state, action) => {
  const isNumber = (data) => {
    if (typeof data === "string") {
      return parseFloat(data).toFixed(2);
    } else {
      return parseFloat(data).toFixed(2);
    }
  };

  switch (action.type) {
    case "ADD_TO_CART":
      // Check if the product is already in the cart
      const existingItem = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );

      if (existingItem) {
        renderToastMsg(
          `Added 1 ${action.payload.product.name} to the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) + parseFloat(action.payload.product.salesPrice)
          ).toFixed(2)
        );



        return {
          ...state,
          items: state.items.map((item) =>
            item.product.id === action.payload.product.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
          total: updatedTotal,
        };
      } else {
        renderToastMsg(
          `Added 1 ${action.payload.product.name} to the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) + parseFloat(action.payload.product.salesPrice)
          ).toFixed(2)
        );


        return {
          ...state,
          items: [
            ...state.items,
            { product: action.payload.product, quantity: 1 },
          ],
          total: updatedTotal,
        };
      }

    case "REMOVE_FROM_CART":
      const itemToRemove = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );
      if (itemToRemove && itemToRemove.quantity > 1) {
        renderToastMsg(
          `Removed 1 ${action.payload.product.name} from the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) - parseFloat(action.payload.product.salesPrice)
          ).toFixed(2)
        );


        return {
          ...state,
          items: state.items.map((item) =>
            item.product.id === action.payload.product.id
              ? { ...item, quantity: item.quantity - 1 }
              : item
          ),
          total: updatedTotal,
        };
      } else {
        renderToastMsg(
          `Removed ${itemToRemove.quantity} ${action.payload.product.name} from the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) -
            parseFloat(action.payload.product.salesPrice * itemToRemove.quantity)
          ).toFixed(2)
        );



        return {
          ...state,
          items: state.items.filter(
            (item) => item.product.id !== action.payload.product.id
          ),
          total: updatedTotal,
        };
      }

    case "REMOVE_ITEM":
      const removeFromCart = state.items.find(
        (item) => item.product.id === action.payload.product.id
      );
      if (removeFromCart) {
        renderToastMsg(
          `Removed ${removeFromCart.quantity} ${action.payload.product.name} from the cart`,
          "success"
        );

        const updatedTotal = isNumber(
          (
            parseFloat(state.total) -
            parseFloat(action.payload.product.salesPrice * removeFromCart.quantity)
          ).toFixed(2)
        );



        return {
          ...state,
          items: state.items.filter(
            (item) => item.product.id !== action.payload.product.id
          ),
          total: updatedTotal,
        };
      }
      break;

    case "CLEAR_CART":
      return {
        ...state,
        items: [],
        total: 0.0,
      };

    default:
      return state;
  }
};

// Cart context provider component
export const CartProvider = ({ children }) => {
  const [cartState, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    setToStorage("cart", cartState);
  }, [cartState]);

  return (
    <CartContext.Provider value={{ cartState, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
