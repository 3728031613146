import React, { useState } from 'react'
import styles from "src/page/authentication/auth.module.css";
import { ErrorMessage, Formik } from 'formik';
import OtpInput from "react-otp-input";
import * as yup from "yup";
import { useMutation } from '@tanstack/react-query';
import { ValidatePin } from 'src/api';
import { renderToastMsg } from 'src/utils/functions';

const VerifyPin = ({ setIsPinModalOpen, details, handlePrint }) => {

    const [pin, setPin] = useState("");

    const initialValues = {
        pin: "",
    };

    const validationSchema = yup.object().shape({
        pin: yup
            .string()
            .matches(/^\d{4}$/, "PIN must be 4 digits")
            .required("PIN is required"),
    });

    const { mutate, isLoading } = useMutation(ValidatePin, {
        onSuccess: (res) => {
            if (res) {
                setIsPinModalOpen(false)
                renderToastMsg(res?.message, "success")
                handlePrint()
            }
        },
    })

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => mutate({ action: "verify_allowance_pin", pin: values?.pin, schoolCode: "sicl", studentID: details?.student_id, orderID: details?.id })}
            >
                {({
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col justify-center items-center'>
                            <p className={`${styles.otpLabel} text-center`}>
                                Enter Pin
                            </p>
                            <OtpInput
                                inputType='password'
                                value={pin}
                                onChange={(value) => {
                                    setPin(value);
                                    setFieldValue("pin", value);
                                }}
                                numInputs={4}
                                containerStyle={styles.otpContainer}
                                inputStyle={styles.otpInput}
                                renderInput={(props) => <input {...props} />}
                            />
                            <ErrorMessage
                                name="pin"
                                component={"div"}
                                className="text-red-500 text-[1.2rem] my-3"
                            />
                        </div>

                        <button
                            disabled={isLoading}
                            type="submit"
                            className="w-full p-[15px] bg-primary rounded-[2.5rem] justify-center items-center gap-2.5 inline-flex text-center text-white text-[1.4rem] font-medium leading-none my-[20px] 
                        btn-loader"
                        >
                            Submit
                        </button>

                    </form>
                )}
            </Formik>
        </>
    )
}

export default VerifyPin