import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ProSidebarProvider } from 'react-pro-sidebar';

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const root = ReactDOM.createRoot(document.getElementById('root'));
const listOfCodeToNotRepeat = [500, 404, 406];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (_, error) => !listOfCodeToNotRepeat.includes(error?.cause),
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);

