import { createContext, useContext, useEffect, useState } from "react";
import { getFromStorage, setToStorage } from "src/utils/functions";

const ProtectedRoutesContext = createContext({
    user: true,
    setUser: () => { },
    setToken: () => { },
    token: true,
    setSchool: () => { },
    school: true,
});

export const useProtectedRoutesContext = () => useContext(ProtectedRoutesContext);

const ProtectedRoutesProvider = ({ children }) => {
    const [school, setSchool] = useState(getFromStorage("school"));
    const [user, setUser] = useState(getFromStorage("user"));
    const [token, setToken] = useState(getFromStorage("token"));

    useEffect(() => {
        setToStorage("user", user);
    }, [user]);
    useEffect(() => {
        setToStorage("token", token);
    }, [token]);
    useEffect(() => {
        setToStorage("school", school);
    }, [school]);

    return (
        <ProtectedRoutesContext.Provider value={{ user, setUser, token, setToken, school, setSchool }}>
            {children}
        </ProtectedRoutesContext.Provider>
    );
};
export default ProtectedRoutesProvider;