import React, { useState } from "react";
import { ErrorMessage } from "formik";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import styles from "./custominput.module.css";

const CustomInput = ({
  title,
  name,
  passwordToggle,
  placeholder,
  validate = true,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setVisible((prevState) => !prevState);
  };

  const inputType = passwordToggle ? (visible ? "text" : "password") : "text";

  return (
    <div className={styles.inputWrapper}>
      <label className={styles.title} htmlFor={rest?.id ?? name}>
        {title}
      </label>
      <div className={`has-pwd-icon ${passwordToggle ? "relative" : ""}`}>
        <input
          type={inputType}
          name={name}
          id={name}
          placeholder={placeholder ? placeholder : title}
          className={styles.textInput}
          {...rest}
        />
        {passwordToggle && (
          <div className="absolute inset-y-0 right-0 flex items-cener pr-2">
            {visible ? (
              <BsEye
                className={styles.viewPwd}
                size={20}
                onClick={togglePasswordVisibility}
              />
            ) : (
              <BsEyeSlash
                className={styles.viewPwd}
                size={20}
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
        )}
      </div>
      {validate && (
        <ErrorMessage
          name={name}
          component="div"
          className="text-red-500 text-[1.2rem] my-3"
        />
      )}
    </div>
  );
};

export default CustomInput;
