import { lazy } from "react";
const Login = lazy(() => import("./authentication/Login"));
const TopNav = lazy(() => import("./dashboard/layout/TopNav"));
const Footer = lazy(() => import("./dashboard/layout/Footer"));
const SideBar = lazy(() => import("./dashboard/layout/SideBar"));
const AllOrders = lazy(() => import("./dashboard/accounts/AllOrders"));
const Wallet = lazy(() => import("./dashboard/accounts/Wallet"));
const Settings = lazy(() => import("./dashboard/accounts/Settings"));
const Landing = lazy(() => import("./dashboard/Landing"));
const Orders = lazy(() => import("./dashboard/Orders"));
const Marketplace = lazy(() => import("./dashboard/Marketplace"));
const CheckOut = lazy(() => import("./dashboard/CheckOut"));
const Print = lazy(() => import("./authentication/Print"));


export {
    Login,
    TopNav,
    Footer,
    Landing,
    Orders,
    Marketplace,
    SideBar,
    AllOrders,
    Wallet,
    Settings,
    CheckOut,
    Print
}