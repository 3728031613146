import React from 'react'
import { RiSearch2Line } from 'react-icons/ri'

const CustomInputIcon = ({ ...rest }) => {
    return (
        <div className="relative flex items-center my-[2rem]">
            <input type="text" {...rest} className="relative h-[4.4rem] w-full rounded-[3rem] bg-white pl-[4rem] pr-[3rem] outline-none transition-all duration-200 ease-in-out text-[#576378] border border-primary" />
            <span className="absolute left-5 text-[#576378] transition-all duration-200 ease-in-out focus:text-primary focus-within:text-primary"><RiSearch2Line size="2rem" /></span>
        </div>
    )
}

export default CustomInputIcon